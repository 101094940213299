import styled from "styled-components";

const StyledH1 = styled.h1`
  font-size: 6rem;
  line-height: 110%;
  padding: 0;
  margin: 0;
  @media (max-width: 600px) {
    font-size: 3rem;
  }
`;

export default StyledH1;