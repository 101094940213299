import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import Button from './styles/Button';
import Title from './styles/Title';

const Banner = styled.article`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 2rem;
  height: 100%;
  padding: 0 14%;
  @media (max-width: 768px) {
    padding: 0 2em;
  }
`;

const Shadow = styled.div`
  img {
    box-shadow: 10px 30px 30px 8px rgba(0,0,0,0.5);
  }
`;

export default function AppBanner() {
  return (
    <Banner>
      <div className="title-banner">
        <Title>Introducting the new Cloudfm App</Title>
        <p>Cloudfm is continuing to revolutionise and change the facilities management industry; from focusing on assets and buildings, to focus on engaging customers and efficiently delivering their facility management needs.</p>
        <p>Our App is the next evolution, delivering true real-time, transparent information that builds on the back of the UK's richest source of operational FM data.</p>
        <Button href="/signup">Request early access</Button>
      </div>
      <div className="app-image">
        <Shadow>
          <StaticImage
            src="../images/app-screens.png" 
            alt="App Screenshot"
            imgStyle={{}}
          />
        </Shadow>
      </div>
    </Banner>
  )
}