import React from 'react';
import AppBanner from '../components/AppBanner';
import Layout from "../components/Layout";

const IndexPage = () => {
  return (
    <Layout>
      <AppBanner/>
    </Layout>
  )
}

export default IndexPage
