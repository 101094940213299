import styled from "styled-components";

const StyledButton = styled.a`
  color: var(--white);
  cursor: pointer;
  padding: 1.3rem 3rem;
  font-family: 'Lexend Deca', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-image: var(--buttonGradient);
  background-position: ;
  border-radius: 8px;
  text-align: center;
  text-transform: none;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  display: inline-block;
  text-decoration: none;
`;

export default StyledButton;